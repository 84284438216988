body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (min-width: 1024px) {
  .container {
      max-width: 1264px;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import url('https://fonts.googleapis.com/css2?family=Anton:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abeezee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.Anton400 {
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  letter-spacing: 0.03em;
}

.Montserratparagraph {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.4; /* Adjust this value to decrease spacing between lines */
  margin-bottom: 1.5rem; /* Add space below the paragraph */
  max-width: 510px;
}

.Montserrat{
  font-family: 'Montserrat', sans-serif;
}

.Abeezee {
  font-family: 'Abeezee', sans-serif;
}

.Abeezee400 {
  font-family: 'Abeezee', sans-serif;
  font-weight: 400;
}

.DMSans {
  font-family: 'DM Sans', sans-serif;
  font-weight: 400;
}